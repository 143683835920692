import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { id: "cards" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GoodCard = _resolveComponent("GoodCard")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (card, key) => {
        return (_openBlock(), _createBlock(_component_GoodCard, {
          key: key,
          "card-drink": card
        }, null, 8, ["card-drink"]))
      }), 128))
    ])
  ]))
}