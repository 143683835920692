

export default {
  props: {
    cardDrink: {
      type: Object,
    },
  }
}

