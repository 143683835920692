import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dad753b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "device"
}
const _hoisted_2 = { class: "led-display" }
const _hoisted_3 = { class: "buttons" }
const _hoisted_4 = { class: "buttons" }
const _hoisted_5 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.visible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.cash), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addMoney(10))),
            class: "c-button"
          }, "10"),
          _createElementVNode("button", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addMoney(50))),
            class: "c-button"
          }, "50"),
          _createElementVNode("button", {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.addMoney(100))),
            class: "c-button"
          }, "100"),
          _createElementVNode("button", {
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.addMoney(200))),
            class: "c-button"
          }, "200")
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("button", {
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.pay && _ctx.pay(...args))),
            class: "c-button",
            disabled: (_ctx.cash.toString() < _ctx.drinkCost)
          }, "Pay", 8, _hoisted_5),
          _createElementVNode("button", {
            onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args))),
            class: "c-button"
          }, "Cancel")
        ])
      ]))
    : _createCommentVNode("", true)
}