

import store from '@/store';
import { mapActions } from 'vuex';

export default (await import('vue')).defineComponent({

  emits: ['success', 'error'],

  computed: {
    visible() {
      return store.state.emulator.cardReader.active;
    },

    message() {
      return store.state.emulator.cardReader.status || 'Ready to accept card'
    },

    isDisabled() {
      return store.state.machine.progress > 0
    }

  },

  methods: {

    ...mapActions({
      cardValue: 'cardValue',
    }),

    succes() {
      this.$emit('success');
    },

    error() {
      this.$emit('error');
    },

  },

  beforeUnmount() {
    this.cardValue(0);
  },
})

