

import store from '@/store';

export default (await import('vue')).defineComponent({

  name: 'prepare-page',

  data() {
    return {
      information: 'Preparing drink'
    }
  },

  created() {
    store.commit('caption', this.information);
  },

  computed: {
    infoText() {
      return store.state.infoText;
    },

    progress(){
      return store.state.machine.progress;
    }

  }

})

