

import router from '@/router';
import store from '@/store';

export default (await import('vue')).defineComponent({

  name: 'prepare-page',

  data() {
    return {
      information: 'Drink ready!',
      countDown: 10,
    }
  },

  created() {
    store.commit('caption', this.information);
  },

  mounted() {
      this.homeCountDown();
  },

  computed: {
    infoText() {
      return store.state.infoText;
    },

    progress(){
      return store.state.machine.progress;
    }

  },

  methods: {
    async homeCountDown() {
      const c = this.countDown;
      for (let i=c; i>0; i--) {
        await new Promise((resolve) => {
          setTimeout(()=>{
            this.countDown--;
            resolve(true);
          }, 1000);
        })
      }
      router.push('/');
    }
  }

})

