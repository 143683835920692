
import store from '@/store';


export default (await import('vue')).defineComponent({

  emits: ['onChange'],

  props: {

    componentName: {
      type: String,
      default: 'name',
    },

    componentCaption: {
      type: String,
      default: 'Caption'
    },

    Min: {
      type: Number,
      default: 0
    },

    Max: {
      type: Number,
      default: 6
    },


  },

  methods: {
    minusClick() {
      if (this.value > 0) {
        this.value--;
        this.$emit('onChange', this.componentName, this.value);
      }
    },

    plusClick() {
      if (this.value < this.Max) {
        this.value++;
        this.$emit('onChange', this.componentName, this.value);
      }
    },

  },

  data() {
    return {
      value: 0,
    }
  },

  beforeMount() {
    const a = store.getters;
    if(a) this.value = a[this.componentName] ?? 0
  },

})

