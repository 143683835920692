import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5dad539a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "component left-gradient" }
const _hoisted_2 = { class: "" }
const _hoisted_3 = { class: "select-row" }
const _hoisted_4 = { class: "shadow" }
const _hoisted_5 = { class: "shadow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.componentCaption), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("a", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.minusClick && _ctx.minusClick(...args))),
        class: "pointer"
      }, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_font_awesome_icon, {
            icon: ['fas', 'circle-minus'],
            size: "2xl"
          })
        ])
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.Max, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          key: item,
          class: "shadow"
        }, [
          _createVNode(_component_font_awesome_icon, {
            class: _normalizeClass({'select-color light-shadow': _ctx.value > (item-1)}),
            icon: ['fas', 'minus'],
            size: "xl"
          }, null, 8, ["class"])
        ]))
      }), 128)),
      _createElementVNode("a", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.plusClick && _ctx.plusClick(...args))),
        class: "pointer shadow"
      }, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_font_awesome_icon, {
            icon: ['fas', 'circle-plus'],
            size: "2xl"
          })
        ])
      ])
    ])
  ]))
}