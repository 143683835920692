

import store from '@/store';
import { DrinkCardType } from '@/types';
import SelectCount from '@/components/common/SelectCount.vue';

export default (await import('vue')).defineComponent({

  components: {
    SelectCount
  },

  props: {
    cardId: {
      type: String,
    },
  },

  data() {
    return {

      payName: 'Payment Methods',

      drinkCard: {} as DrinkCardType,

    }
  },

  created() {
    this.drinkCard = store.state.currentDrinkCard ??
      store.state.data.filter(
        (item: DrinkCardType) => item.id.toString()  === this.cardId)[0] ?? {} as DrinkCardType;
  },

  methods: {
    addonCountChange(name: string, value: number) {
      const n = name.split('');
      n[0] = n[0].toUpperCase();
      store.commit('set' + n.join(''), value);
    }
  }


})

