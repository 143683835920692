import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-560f652e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "info-page" }
const _hoisted_2 = { class: "info-page-detail" }
const _hoisted_3 = { class: "shadow font-10rem" }
const _hoisted_4 = { class: "progress-items" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_font_awesome_icon, {
          icon: ['far', 'hourglass-half'],
          spin: ""
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(9, (item) => {
          return _createElementVNode("div", { key: item }, [
            _createElementVNode("div", {
              class: _normalizeClass(["progress-item", {'opacity-09': (_ctx.progress)>(item)}])
            }, null, 2)
          ])
        }), 64))
      ])
    ])
  ]))
}