

import store from '@/store';
import router from '@/router';

export default (await import('vue')).defineComponent({

  name: 'info-page',

  data() {
    return {
      information: 'Information'
    }
  },

  created() {
    store.commit('caption', this.information);
  },

  computed: {
    infoText() {
      return store.state.infoText;
    }
  },

})

