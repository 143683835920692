

export default (await import('vue')).defineComponent({
  props: {
    payName: {
      type: String,
      default: 'Sum to pay'
    },

    paySum: {
      type: String,
      default: ''
    },

    payCurr: {
      type: String,
      default: ''
    },

  }
})

