

import {mapActions} from 'vuex';
import MainLayout from './layouts/MainLayout.vue';

export default (await import('vue')).defineComponent({
  components: {
    MainLayout
  },

  data() {
    return {
      page:{
        caption:"",
      },
    }
  },

  async mounted() {
    await this.getData('cache');
  },

  methods: {
    ...mapActions({
      getData: 'getData',
    }),
  }

})

