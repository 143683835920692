import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d2301e2e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "device"
}
const _hoisted_2 = { class: "led-display" }
const _hoisted_3 = { class: "buttons" }
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.visible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.message), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.succes && _ctx.succes(...args))),
            class: "c-button",
            disabled: _ctx.isDisabled
          }, "Success", 8, _hoisted_4),
          _createElementVNode("button", {
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.error && _ctx.error(...args))),
            class: "c-button"
          }, "Error")
        ])
      ]))
    : _createCommentVNode("", true)
}