

import TopMessage from '@/components/common/TopMessage.vue';
import EmulatorState from '@/components/emulator/EmulatorState.vue';

export default (await import('vue')).defineComponent({

  components: {
    TopMessage,
    EmulatorState,
  },

})
