import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3e487e42"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "info-page" }
const _hoisted_2 = { class: "info-page-detail" }
const _hoisted_3 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.infoText), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("a", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1))),
          class: "pointer"
        }, [
          _createVNode(_component_font_awesome_icon, {
            icon: ['fa', 'fa-circle-arrow-left'],
            size: "2xl"
          })
        ])
      ])
    ])
  ]))
}