import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dac30130"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid-2" }
const _hoisted_2 = { class: "card-detail" }
const _hoisted_3 = { class: "card-detail" }
const _hoisted_4 = { class: "coffee-cup shadow-blur-16" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DrinkCardOrder = _resolveComponent("DrinkCardOrder")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_PaySum = _resolveComponent("PaySum")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "card-content-detail",
        style: _normalizeStyle([{"background-position":"center","background-size":"160%"}, {
          backgroundImage: `url(${require('@/assets/images/' + _ctx.drinkCard?.image)})`}])
      }, [
        _createVNode(_component_DrinkCardOrder, {
          "card-name": _ctx.drinkCard?.name,
          milk: _ctx.addons.milk,
          sugar: _ctx.addons.sugar
        }, null, 8, ["card-name", "milk", "sugar"])
      ], 4)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: "card-content-detail",
        style: _normalizeStyle([{"background-position":"center","background-size":"120%"}, {
          backgroundImage: `url(${require('@/assets/images/money.jpg')})` }])
      }, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.payName), 1),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_font_awesome_icon, {
            icon: ['far', 'credit-card'],
            "beat-fade": "",
            size: "xl"
          })
        ]),
        _createVNode(_component_PaySum, {
          "pay-name": 'Sum to pay',
          "pay-sum": _ctx.drinkCard?.price,
          "pay-curr": _ctx.drinkCard?.currency
        }, null, 8, ["pay-sum", "pay-curr"])
      ], 4)
    ])
  ]))
}