

import store from '@/store';
import { mapActions } from 'vuex';

export default (await import('vue')).defineComponent({

  emits: ['pay', 'cancel', 'addCash'],

  computed: {
    visible() {
      return store.state.emulator.cashReader.active;
    },

    cash() {
      return store.state.emulator.cashReader.value;
    },

    drinkCost() {
      return store.state.currentDrinkCard.price;
    }

  },

  methods: {

    ...mapActions({
      cashValue: 'cashValue',
    }),

    pay() {
      this.$emit('pay');
    },

    cancel() {
      this.$emit('cancel');
    },

    addMoney(value: number) {
      this.$emit('addCash', value);
    }
  },

})

