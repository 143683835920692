

import store from '@/store';

export default (await import('vue')).defineComponent({

  computed: {
    message() {
      return store.state.page.caption
    }
  }

})

