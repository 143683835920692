

export default (await import('vue')).defineComponent({

  props: {
    cardName: {
      type: String,
      default: 'Card Name'
    },

    sugar: {
      type: Number,
      default: 0
    },

    milk: {
      type: Number,
      default: 0
    },
  }

})

