

import store from '@/store';
import {mapActions} from 'vuex';
import CardReader from './CardReader.vue';
import CashReader from './CashReader.vue';
import { Emulator } from './emulator';
import router from '@/router';

let e1 = new Emulator();

export default (await import('vue')).defineComponent({

  components: { CardReader, CashReader },

  data() {
    return {
      // emulator: {}
    }
  },

  computed: {

    currentDrink(){
      return store.state.currentDrinkCard;
    },

    milk() {
      return store.state.addons.milk;
    },

    sugar() {
      return store.state.addons.sugar;
    },

    emulator() {
      return store.state.emulator;
    },

    machineProgress() {
      return store.state.machine.progress;
    },

    machineActive() {
      return store.state.machine.active;
    },

    cash() {
      return store.state.emulator.cashReader.status;
    }
  },

  async created() {
    await this.cardValue(0);
    await this.cashValue(0);
    await this.cashActive(false);
    await this.cardActive(false);
  },

  methods: {
    ...mapActions({
      cardValue: 'cardValue',
      cashValue: 'cashValue',
      cashActive: 'cashActive',
      cardActive: 'cardActive',
      cardStatus: 'cardStatus',
      cashStatus: 'cashStatus',
      textInfo: 'textInfo',
    }),

    onCardError() {
      e1.payCardError((value) => {
        console.log(value);
        setTimeout(()=>{
          this.textInfo('Card payment error!');
          router.push('/info');
          this.cardStatus('Insert payment card');
        }, 2000)
      })
    },

    async onCardSuccess() {
      await e1.payCardSuccess((value) => {
        console.log('oga',value);
      })

      store.commit('machineProgress', 1);

      router.push('/prepare');

      await e1.prepareDrink((value)=>{
        // console.log('drink ready:', value);
        router.push('/ready');
      })

    },

    onCashCancel() {
      if (parseInt(store.state.emulator.cashReader.value ?? 0) > 0) {
        this.cashValue('Money back');
        setTimeout(() => {
          // this.cashStatus('');
          this.cashValue(0);
          router.push('/');
        }, 2000)
      }
      this.cashStatus
      e1.payCashCancel((value) => {
        console.log(value);
      })
    },

    onCashPay() {
      e1.payCashPay((value) => {

        store.commit('machineProgress', 1);
        router.push('/prepare');

        e1.prepareDrink((value)=>{
          this.cashValue(0);
          router.push('/ready');
        })

      })
    },

    onAddCash(value: number) {
      this.cashValue(parseInt(store.state.emulator.cashReader.value) + value)
    }

  }

})

