import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "price" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to:  '/detail/id=' + $props.cardDrink?.id
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "card-content",
          style: _normalizeStyle([{"background-position":"center","background-size":"120%"}, {
          backgroundImage: `url(${require('@/assets/images/' + $props.cardDrink?.image)})`
        }])
        }, [
          _createElementVNode("h2", null, _toDisplayString($props.cardDrink?.name), 1),
          _createElementVNode("div", _hoisted_2, _toDisplayString($props.cardDrink?.price) + " " + _toDisplayString($props.cardDrink?.currency), 1)
        ], 4)
      ])
    ]),
    _: 1
  }, 8, ["to"]))
}